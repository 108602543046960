import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import '../App.css';

import Header from '../components/navbar.jsx';
import Table from '../components/table.jsx';
import OnClickTable from '../components/onclicktable.jsx';
import DynamicTable from '../components/dynamictable.jsx';
import BFooter from '../components/footer.jsx';
import Scroller from '../components/topscroll.jsx';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Gamescript = () => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedScript, setSelectedScript] = useState(null);
  const navigate = useNavigate();
  const query = useQuery();
  const gameID = query.get('gameID');
  const scriptID = query.get('scriptID');
  const line = query.get('line');

  useEffect(() => {
    if (gameID) {
      import(`../scripts/${gameID}.json`)
        .then((module) => {
          const data = module.default || module;
          if (data && data.scripts) {
            setSelectedGame(data);
          } else {
            console.error('Invalid JSON structure:', data);
          }
        })
        .catch((error) => console.error('Error fetching game data:', error));
    }
  }, [gameID]);

  useEffect(() => {
    if (gameID && scriptID) {
      import(`../scripts/${gameID}/${scriptID}.json`)
        .then((module) => {
          const data = module.default || module;
          if (data) {
            console.log('Loaded data.');
            setSelectedScript(data);
          } else {
            console.error('Invalid JSON structure:', data);
          }
        })
        .catch((error) => console.error('Error fetching script data:', error));
    }
  }, [scriptID, gameID]);

  useEffect(() => {
    if (selectedScript && line) {
      setTimeout(() => {
        const element = document.getElementById(`line-${line}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200)
    }
  }, [selectedScript, line]);

  const handleScriptClick = (scriptID) => {
    navigate(`/gamescripts?gameID=${gameID}&scriptID=${scriptID}`);
    setSelectedScript(null);
  };

  const handleFilenameClick = (scriptID, filename) => {
    navigate(`/gamescripts?gameID=${gameID}&scriptID=${scriptID}&filename=${filename}`);
    setSelectedGame(null);
  };

  return (
    <div className="App">
      <div>
        <Header />
      </div>
      <header className="container white-color">
        <div className="white-color">
          <div className="flex flextbl">
            <span className='span'><a href="/gamescripts"><b>Browse scripts by game</b></a></span>
            {!selectedScript && <Table />}
            {!selectedScript ? (
              <OnClickTable game={selectedGame} onScriptClick={handleScriptClick} />
            ) : (
              <DynamicTable script={selectedScript} onFilenameClick={handleFilenameClick} />
            )}
          </div>
        </div>
      </header>
      <>
        <Scroller />
      </>
    </div>
  );
};

export default Gamescript;